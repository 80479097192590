import { useEffect, useState } from "react"
import trpc_client from "src/lib/trpc"
import { useAuth } from "src/providers/AuthProvider"

import {
	PublicSafePersona,
	PublicSafeTelegramBot,
} from "../../../../../api/src/models/persona/persona.schema"

import { Settings } from "./Settings.component"

interface BotSettingsProps {
	persona_id: string
}

export const BotSettings: React.FC<BotSettingsProps> = ({ persona_id }) => {
	const [persona, setPersona] = useState<PublicSafePersona | null>(null)
	const [telegramBot, setTelegramBot] = useState<PublicSafeTelegramBot | null>(
		null
	)
	const [is_fetch_loading, setIsFetchLoading] = useState(true)
	const [error, setError] = useState<string | null>(null)
	const [isEditing, setIsEditing] = useState(false)
	const [botData, setBotData] = useState({
		displayName: "",
		bio: "",
		description: "",
	})
	const { user, is_loading: is_auth_loading } = useAuth()

	useEffect(() => {
		fetchData()
	}, [persona_id])

	const fetchData = async () => {
		setIsFetchLoading(true)
		setError(null)
		try {
			const [fetchedPersona, bot] = await Promise.all([
				trpc_client.persona.getById.query({ persona_id }),
				trpc_client.bot.getBot.query({ persona_id }).catch(() => null),
			])
			setPersona(fetchedPersona)
			setTelegramBot(bot)
			if (bot) {
				setBotData({
					displayName: bot.display_name || "",
					bio: bot.bio || "",
					description: bot.description || "",
				})
			}
		} catch (error) {
			console.error("Error fetching persona or bot:", error)
			setError("Failed to load bot settings. Please try again.")
		} finally {
			setIsFetchLoading(false)
		}
	}

	const handleBotCreation = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setIsFetchLoading(true)
		try {
			const formData = new FormData(event.currentTarget)
			const token = formData.get("token") as string
			await trpc_client.bot.addBot.mutate({ persona_id, token })
			await fetchData()
		} catch (error) {
			console.error("Error creating bot:", error)
			setError("Failed to create bot. Please try again.")
		} finally {
			setIsFetchLoading(false)
		}
	}

	const handleBotUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setIsFetchLoading(true)
		try {
			await trpc_client.bot.updateBot.mutate({
				persona_id,
				update: {
					display_name: botData.displayName,
					bio: botData.bio,
					description: botData.description,
				},
			})
			await fetchData()
			setIsEditing(false)
		} catch (error) {
			console.error("Error updating bot:", error)
			setError("Failed to update bot. Please try again.")
		} finally {
			setIsFetchLoading(false)
		}
	}

	const updateBotData = (field: keyof typeof botData) => (value: string) => {
		setBotData(prev => ({ ...prev, [field]: value }))
	}

	if (!persona) {
		return <div>Persona not found</div>
	}
	if (is_auth_loading || !user) {
		return <div>Loading</div>
	}
	if (error) {
		return <div>{error}</div>
	}
	return (
		<Settings
			persona={persona}
			user={user}
			telegram_bot={telegramBot}
			isLoading={is_fetch_loading}
			isEditing={isEditing}
			botData={botData}
			setIsEditing={setIsEditing}
			updateBotData={updateBotData}
			handleBotCreation={handleBotCreation}
			handleBotUpdate={handleBotUpdate}
		/>
	)
}
