import { ReactNode } from "react"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"

import { POSTHOG_HOST, POSTHOG_PUBLIC_KEY } from "../lib/constants"

if (typeof window !== "undefined" && process.env.NODE_ENV !== "development") {
	console.log("Initalize PostHog Analytics")
	posthog.init(POSTHOG_PUBLIC_KEY as string, {
		api_host: POSTHOG_HOST,
		ui_host: "https://app.posthog.com",
	})
}

interface PHProviderProps {
	children: ReactNode
}

export function PHProvider({ children }: PHProviderProps) {
	if (process.env.NODE_ENV === "development") {
		console.log("PostHog is disabled in development")
		return <>{children}</>
	}

	return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}
