import { useEffect } from "react"
import {
	STRIPE_PRICING_TABLE_ID,
	STRIPE_PUBLISHABLE_KEY,
} from "src/lib/constants"
import { useAuth } from "src/providers/AuthProvider"

import { Card } from "../ui/Card"

import { SectionHeader } from "./SectionHeader"

declare global {
	namespace JSX {
		interface IntrinsicElements {
			"stripe-pricing-table": React.DetailedHTMLProps<
				React.HTMLAttributes<HTMLElement>,
				HTMLElement
			>
		}
	}
}

export const Pricing = () => {
	const { user, is_loading } = useAuth()
	const origin = window.location.origin

	useEffect(() => {
		if (
			document.querySelector(
				`script[src="https://js.stripe.com/v3/pricing-table.js"]`
			)
		) {
			return
		}
		const script = document.createElement("script")
		script.src = "https://js.stripe.com/v3/pricing-table.js"
		script.async = true
		document.body.appendChild(script)

		return () => {
			document.body.removeChild(script)
		}
	}, [])

	return (
		<section id="pricing" className="">
			<Card className="overflow-hidden">
				<SectionHeader
					subheader="Pricing plans"
					title="Simple pricing for everyone"
					description="Choose an affordable plan that's packed with the best features for engaging your audience, creating customer loyalty, and driving sales."
				/>
				{is_loading ? (
					<div className="flex justify-center p-8">
						<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
					</div>
				) : (
					<stripe-pricing-table
						pricing-table-id={STRIPE_PRICING_TABLE_ID}
						publishable-key={STRIPE_PUBLISHABLE_KEY}
						customer-email={user?.email}
						customer-success-url={`${origin}/settings`}
						customer-cancel-url={origin}
						client-reference-id={user?.team_id}
					/>
				)}
			</Card>
		</section>
	)
}
