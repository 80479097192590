import { z } from "zod"

// Define schema for environment variables
const env_schema = z.object({
	REACT_APP_POSTHOG_KEY: z.string().min(1, "PostHog key is required"),
	REACT_APP_POSTHOG_HOST: z.string().url("PostHog host must be a valid URL"),
	REACT_APP_STRIPE_PUBLISHABLE_KEY: z
		.string()
		.min(1, "Stripe publishable key is required"),
	REACT_APP_STRIPE_PRICING_TABLE_ID: z
		.string()
		.min(1, "Stripe pricing table ID is required"),
	REACT_APP_STRIPE_REVIEW_PRICE_PAYMENT_LINK: z
		.string()
		.url("Stripe payment link must be a valid URL"),
	REACT_APP_CALL_BOOKING_URL: z.string().url("Booking URL must be a valid URL"),
})

// Validate environment variables
const validate_env_vars = () => {
	try {
		const result = env_schema.parse({
			REACT_APP_POSTHOG_KEY: process.env.REACT_APP_POSTHOG_KEY,
			REACT_APP_POSTHOG_HOST: process.env.REACT_APP_POSTHOG_HOST,
			REACT_APP_STRIPE_PUBLISHABLE_KEY:
				process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
			REACT_APP_STRIPE_PRICING_TABLE_ID:
				process.env.REACT_APP_STRIPE_PRICING_TABLE_ID,
			REACT_APP_STRIPE_REVIEW_PRICE_PAYMENT_LINK:
				process.env.REACT_APP_STRIPE_REVIEW_PRICE_PAYMENT_LINK,
			REACT_APP_CALL_BOOKING_URL: process.env.REACT_APP_CALL_BOOKING_URL,
		})
		console.log("Environment variables validated successfully")
		return result
	} catch (error) {
		console.error("Environment validation failed:", error)
		throw error
	}
}

const env_vars = validate_env_vars()

export const POSTHOG_PUBLIC_KEY = env_vars.REACT_APP_POSTHOG_KEY
export const POSTHOG_HOST = env_vars.REACT_APP_POSTHOG_HOST
export const STRIPE_PUBLISHABLE_KEY = env_vars.REACT_APP_STRIPE_PUBLISHABLE_KEY
export const STRIPE_PRICING_TABLE_ID =
	env_vars.REACT_APP_STRIPE_PRICING_TABLE_ID
export const STRIPE_REVIEW_PRICE_PAYMENT_LINK =
	env_vars.REACT_APP_STRIPE_REVIEW_PRICE_PAYMENT_LINK
export const CALL_BOOKING_URL = env_vars.REACT_APP_CALL_BOOKING_URL
