import { useEffect, useState } from "react"
import { toast } from "sonner"
import trpc_client from "src/lib/trpc"

import { SelectTeam } from "../../../api/src/models/team/team.schema"

export const useSubscription = () => {
	const [subscription, setSubscription] = useState<SelectTeam | null>(null)
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		const fetchSubscription = async () => {
			try {
				const team = await trpc_client.user.getUsersTeam.query()

				if (!team) {
					setSubscription(null)
					return
				}

				if (team.stripe_customer_id) {
					setSubscription(team)
				} else {
					setSubscription(null)
				}
			} catch (error) {
				console.error("Error fetching subscription:", error)
				toast.error("Failed to load subscription data")
				setSubscription(null)
			} finally {
				setIsLoading(false)
			}
		}

		fetchSubscription()
	}, [])

	return {
		subscription,
		isLoading,
	}
}
