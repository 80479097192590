import React, {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"

import { UserJson } from "../../../api/src/models/user/user.model"

interface AuthContextType {
	user: UserJson | null
	is_loading: boolean
	login: (email: string) => Promise<void>
	logout: () => Promise<void>
	register: (email: string, name: string) => Promise<void>
	verify: (token: string) => Promise<void>
}

const REACT_APP_API_URL = process.env.REACT_APP_API_URL as string

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const useAuth = () => {
	const context = useContext(AuthContext)
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider")
	}
	return context
}

interface AuthProviderProps {
	children: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [user, setUser] = useState<UserJson | null>(null)
	const [is_loading, setIsLoading] = useState(true)
	const navigate = useNavigate()

	useEffect(() => {
		// Check for existing session on component mount
		const checkSession = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(`${REACT_APP_API_URL}/auth/session`, {
					method: "GET",
					credentials: "include",
					// mode: "same-origin",
					headers: {
						"Content-Type": "application/json",
					},
				})
				if (response.ok) {
					const userData = await response.json()
					setUser(userData)
				}
			} catch (error) {
				console.error("Error checking session:", error)
			} finally {
				setIsLoading(false)
			}
		}

		checkSession()
	}, [])

	const login = async (email: string) => {
		try {
			const response = await fetch(`${REACT_APP_API_URL}/auth/login`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email }),
			})

			if (!response.ok) {
				const errorData = await response.json()
				throw new Error(errorData.error || "Login failed")
			}

			navigate("/verify")
		} catch (error) {
			console.error("Login error:", error)
			throw error
		}
	}

	const logout = async () => {
		try {
			const response = await fetch(`${REACT_APP_API_URL}/auth/logout`, {
				method: "POST",
				credentials: "include",
			})

			if (!response.ok) {
				throw new Error("Logout failed")
			}
			setUser(null)
			navigate("/login")
		} catch (error) {
			console.error("Logout error:", error)
			throw error
		}
	}

	const register = async (email: string, name: string) => {
		try {
			const response = await fetch(`${REACT_APP_API_URL}/auth/register`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email, name }),
			})

			if (!response.ok) {
				throw new Error("Registration failed")
			}
			navigate("verify")
		} catch (error) {
			console.error("Registration error:", error)
			throw error
		}
	}

	const verify = async (token: string) => {
		try {
			const response = await fetch(
				`${REACT_APP_API_URL}/auth/verify?token=${token}`,
				{
					method: "POST",
					credentials: "include",
				}
			)

			if (!response.ok) {
				const errorData = await response.json()
				throw new Error(errorData.error || "Verification failed")
			}

			const userData = await response.json()
			setUser(userData)
			navigate("/onboarding/start")
		} catch (error) {
			console.error("Verification error:", error)
			toast.error("Verification failed. Please try again.")
			navigate("/login")
		}
	}

	const value = {
		user,
		is_loading,
		login,
		logout,
		register,
		verify,
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
