import { useEffect, useState } from "react"
import { Button } from "src/components/ui/Button"
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import { Input } from "src/components/ui/Input"
import { Label } from "src/components/ui/Label"
import { ScrollArea } from "src/components/ui/ScrollArea"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "src/components/ui/Select"
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "src/components/ui/Tabs"
import { Textarea } from "src/components/ui/Textarea"
import { TooltipCard } from "src/components/ui/TooltipCard"
import trpc_client from "src/lib/trpc"

// Updated import
import { UpdatePersonaDetails } from "../../../../../api/src/models/persona/persona.schema"

import { initialQuestions, Question, QuestionGroup } from "./questions"
import { tooltipContent } from "./tooltipContent"

interface PersonaQuestionsCardProps {
	personaId: string
	onUpdateDetails: (data: Partial<UpdatePersonaDetails>) => Promise<void>
}

export const PersonaQuestionsCard: React.FC<PersonaQuestionsCardProps> = ({
	personaId,
	onUpdateDetails,
}) => {
	const [questions, setQuestions] = useState<QuestionGroup[]>(initialQuestions)
	const [editMode, setEditMode] = useState(false)
	const [activeField, setActiveField] = useState<string | null>(null)

	const fetchPersonaDetails = async () => {
		try {
			const data = await trpc_client.persona.getWithDetails.query({
				persona_id: personaId,
			})

			const updatedQuestions = questions.map(group => ({
				...group,
				questions: group.questions.map(item => {
					if (Array.isArray(item)) {
						return item.map(subItem => ({
							...subItem,
							answer:
								data.details?.[
									subItem.question.toLowerCase().replace(/ /g, "_")
								] || subItem.answer,
						}))
					}
					return {
						...item,
						answer:
							data.details?.[item.question.toLowerCase().replace(/ /g, "_")] ||
							item.answer,
					}
				}),
			}))
			setQuestions(updatedQuestions)
		} catch (error) {
			console.error("Failed to fetch persona details:", error)
		}
	}

	useEffect(() => {
		fetchPersonaDetails()
	}, [personaId])

	const handleInputChange = (
		groupIndex: number,
		questionIndex: number,
		value: string,
		subIndex?: number
	) => {
		const updatedQuestions = [...questions]
		if (subIndex !== undefined) {
			const subItem = (
				updatedQuestions[groupIndex].questions[questionIndex] as Question[]
			)[subIndex]
			subItem.answer = value
		} else {
			const item = updatedQuestions[groupIndex].questions[
				questionIndex
			] as Question
			item.answer = value
		}
		setQuestions(updatedQuestions)
	}

	const handleSave = async () => {
		const updatedData: Partial<UpdatePersonaDetails> = {}
		questions.forEach(group => {
			group.questions.forEach(item => {
				if (Array.isArray(item)) {
					item.forEach(subItem => {
						if (subItem.answer) {
							updatedData[
								subItem.question
									.toLowerCase()
									.replace(/ /g, "_") as keyof UpdatePersonaDetails
							] = subItem.answer
						}
					})
				} else if (item.answer) {
					updatedData[
						item.question
							.toLowerCase()
							.replace(/ /g, "_") as keyof UpdatePersonaDetails
					] = item.answer
				}
			})
		})

		try {
			await onUpdateDetails(updatedData)
			await fetchPersonaDetails()
			setEditMode(false)
		} catch (error) {
			console.error("Failed to update persona details:", error)
		}
	}

	const handleEdit = () => {
		setEditMode(true)
	}

	const getTooltipKey = (question: string) => {
		return question
			.toLowerCase()
			.replace(/[?',]/g, "")
			.replace(/\//g, "_")
			.replace(/\s+/g, "_")
	}

	const renderQuestion = (
		item: Question,
		groupIndex: number,
		questionIndex: number,
		subIndex?: number
	) => {
		if (item.condition) {
			const conditionField = questions
				.flatMap(group => group.questions)
				.find(
					q => !Array.isArray(q) && q.question === item.condition?.field
				) as Question | undefined
			if (conditionField && conditionField.answer !== item.condition.value) {
				return null
			}
		}

		return (
			<div
				key={subIndex !== undefined ? subIndex : questionIndex}
				className="space-y-2"
			>
				<Label
					htmlFor={`question-${groupIndex}-${questionIndex}${
						subIndex !== undefined ? `-${subIndex}` : ""
					}`}
					className="text-stone-light"
				>
					{item.display_text || item.question}
				</Label>
				{editMode ? (
					item.type === "textarea" ? (
						<Textarea
							id={`question-${groupIndex}-${questionIndex}${
								subIndex !== undefined ? `-${subIndex}` : ""
							}`}
							value={item.answer}
							onChange={e =>
								handleInputChange(
									groupIndex,
									questionIndex,
									e.target.value,
									subIndex
								)
							}
							onFocus={() => setActiveField(getTooltipKey(item.question))}
							onBlur={() => setActiveField(null)}
							className="w-full"
							rows={4}
						/>
					) : item.type === "select" ? (
						<Select
							value={item.answer}
							onValueChange={value =>
								handleInputChange(groupIndex, questionIndex, value, subIndex)
							}
						>
							<SelectTrigger className="w-full">
								<SelectValue placeholder="Select an option" />
							</SelectTrigger>
							<SelectContent>
								{item.options?.map(option => (
									<SelectItem key={option} value={option}>
										{option}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					) : (
						<Input
							id={`question-${groupIndex}-${questionIndex}${
								subIndex !== undefined ? `-${subIndex}` : ""
							}`}
							value={item.answer}
							onChange={e =>
								handleInputChange(
									groupIndex,
									questionIndex,
									e.target.value,
									subIndex
								)
							}
							onFocus={() => setActiveField(getTooltipKey(item.question))}
							onBlur={() => setActiveField(null)}
							className="w-full"
						/>
					)
				) : (
					<p className="subheader">{item.answer}</p>
				)}
			</div>
		)
	}

	return (
		<Card className="w-full">
			<CardHeader className="flex flex-row justify-between items-center">
				<CardTitle>Persona Questions</CardTitle>
				<div className="flex flex-row gap-2">
					{editMode ? (
						<Button onClick={handleSave}>Save</Button>
					) : (
						<Button variant="outline" onClick={handleEdit}>
							Edit
						</Button>
					)}
				</div>
			</CardHeader>
			<CardContent>
				<Tabs
					defaultValue={questions[0].title.toLowerCase().replace(/ /g, "-")}
					className="w-full"
				>
					<TabsList className="flex flex-row items-center w-fit">
						{questions.map(group => (
							<TabsTrigger
								key={group.title}
								value={group.title.toLowerCase().replace(/ /g, "-")}
							>
								{group.title}
							</TabsTrigger>
						))}
					</TabsList>
					<div className="relative w-full">
						{questions.map((group, groupIndex) => (
							<TabsContent
								key={group.title}
								value={group.title.toLowerCase().replace(/ /g, "-")}
								className={`grid ${
									editMode ? "grid-cols-4" : "grid-cols-1"
								} gap-4 w-full`}
							>
								<ScrollArea
									className={editMode ? "col-span-3 w-full" : "w-full"}
								>
									<div className="grid gap-4 p-2">
										{group.questions.map((item, questionIndex) => (
											<div key={questionIndex} className="space-y-2">
												{Array.isArray(item) ? (
													<div className="grid grid-cols-2 gap-4">
														{item.map((subItem, subIndex) =>
															renderQuestion(
																subItem,
																groupIndex,
																questionIndex,
																subIndex
															)
														)}
													</div>
												) : (
													renderQuestion(item, groupIndex, questionIndex)
												)}
											</div>
										))}
									</div>
								</ScrollArea>
								{editMode && (
									<div className="col-span-1">
										<div className="sticky top-4">
											<TooltipCard
												activeField={activeField}
												tooltipContent={tooltipContent}
												defaultTitle="Field Information"
											/>
										</div>
									</div>
								)}
							</TabsContent>
						))}
					</div>
				</Tabs>
			</CardContent>
		</Card>
	)
}
