import React from "react"
import { Formik } from "formik"
import {
	Camera,
	Mail,
	PersonStanding,
	Quote,
	Share,
	Speech,
	User,
} from "lucide-react"
import { S3Response } from "react-s3-uploader"
import { Input } from "src/components/ui/Input"
import { Label } from "src/components/ui/Label"
import { ProfilePictureUpload } from "src/components/ui/ProfilePictureUpload"
import { Textarea } from "src/components/ui/Textarea"
import { TooltipCard, TooltipContent } from "src/components/ui/TooltipCard"
import { z } from "zod"
import { toFormikValidationSchema } from "zod-formik-adapter"

import trpc_client from "../../lib/trpc"

import { SubmitButton } from "./SubmitButton"

const PersonaSchema = z.object({
	avatar_image: z.string().nullable(),
	name: z.string().min(1, "Name is required"),
	about: z.string().min(1, "About is required"),
	physical_description: z.string().min(1, "Physical description is required"),
	tone: z.string().min(1, "Tone is required"),
	instagram: z.string().optional(),
	twitter: z.string().optional(),
	reddit: z.string().optional(),
	website: z.string().optional(),
	email: z.string().email("Invalid email").optional(),
})

type CreatePersona = z.infer<typeof PersonaSchema>

interface CreatePersonaProps {
	onSuccess?: (persona_id: string) => void
	invite_content_creator: boolean
}

const CreatePersona: React.FC<CreatePersonaProps> = ({
	onSuccess,
	invite_content_creator,
}) => {
	const [activeField, setActiveField] = React.useState<string | null>(null)

	const getSignedUrl = (file: File, callback: (params: S3Response) => void) =>
		trpc_client.persona.profile_picture.getSignedUrl
			.query({
				file: file.name,
				content_type: file.type,
			})
			.then(signedUrl => callback(signedUrl))

	const tooltipContent: Record<string, TooltipContent> = {
		picture: {
			icon: Camera,
			text: <span>Upload a profile picture for your persona.</span>,
		},
		name: {
			icon: User,
			text: (
				<span>
					Enter the name of your persona and this is what end users will see
					when they message them.
				</span>
			),
		},
		about: {
			icon: Quote,
			text: (
				<span>
					This is what the user will see within your profile, below your profile
					picture. Akin to Instagram / Onlyfans bio.
					<br />
					<br />
					For example, you might write:{" "}
					<b>&quot;21, Latina Booty & Sweet Southern Charm 🍑😈&quot;</b>
				</span>
			),
		},
		physical_description: {
			icon: PersonStanding,
			text: (
				<span>
					This is what is given to the AI to use to talk like persona,{" "}
					<b>more details the better</b>. <br />
					<br />
					Try to include:
					<ul className="my-2 ml-4 list-disc">
						<li>
							facial features (e.g., &quot;blue eyes, freckled face&quot;)
						</li>
						<li>body type (e.g., &quot;athletic build&quot;)</li>
						<li>height (e.g., &quot;5&apos;8&quot;)</li>
						<li>hair color (e.g., &quot;brown hair&quot;)</li>
						<li>eye color (e.g., &quot;green eyes&quot;)</li>
					</ul>
					and any other distinguishing features (e.g., &quot;a scar on the left
					cheek&quot;)
				</span>
			),
		},
		tone: {
			icon: Speech,
			text: (
				<span>
					The manner in which the AI will talk like your persona.
					<br />
					<br />
					For example, you could specify:{" "}
					<b>&quot;casual, shy and friendly&quot;</b> or{" "}
					<b>&quot;Flirty, forward, sarcastic.&quot;</b>
				</span>
			),
		},
		socials: {
			icon: Share,
			text: <span>Enter the social media handles for your persona.</span>,
		},
		email: {
			icon: Mail,
			text: (
				<span>
					Optionally enter the email address of the content creator to invite
					them to the team. <br />
					<br />
					Leave blank if not applicable.
				</span>
			),
		},
	}

	return (
		<div className="grid grid-cols-3 gap-4">
			<Formik
				initialValues={{
					avatar_image: null,
					name: "",
					about: "",
					physical_description: "",
					tone: "",
					instagram: "",
					twitter: "",
					reddit: "",
					website: "",
					email: "",
				}}
				validationSchema={toFormikValidationSchema(PersonaSchema)}
				onSubmit={async (values, { setSubmitting }) => {
					try {
						setSubmitting(true)
						const persona_data = {
							...values,
							created_at: new Date(),
							updated_at: new Date(),
						}
						const persona_id = await trpc_client.persona.create.mutate(
							persona_data
						)
						onSuccess?.(persona_id)
					} catch (error) {
						console.error("Error creating persona:", error)
						alert(
							"An error occurred while creating the persona. Please try again."
						)
					} finally {
						setSubmitting(false)
					}
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
					isSubmitting,
				}) => (
					<form className="grid col-span-2 gap-4" onSubmit={handleSubmit}>
						<div className="flex flex-col gap-2">
							<Label htmlFor="picture">Profile Picture of the bot</Label>
							<ProfilePictureUpload
								onImageChange={image => setFieldValue("avatar_image", image)}
								getSignedUrl={getSignedUrl}
							/>
						</div>
						<div>
							<Label htmlFor="name">Name</Label>
							<Input
								type="text"
								id="name"
								name="name"
								className="mt-1"
								placeholder="Name"
								required
								value={values.name}
								onChange={handleChange}
								onBlur={handleBlur}
								onFocus={() => setActiveField("name")}
							/>
							{errors.name && touched.name && (
								<span className="mt-1 text-sm text-red-root">
									{errors.name}
								</span>
							)}
						</div>
						<div>
							<Label htmlFor="about" className="flex gap-1 items-center mb-1">
								About
							</Label>
							<Input
								type="text"
								id="about"
								name="about"
								className="mt-1"
								placeholder="Girl next door from the countryside, here you make all your dreams come true."
								required
								value={values.about}
								onChange={handleChange}
								onBlur={handleBlur}
								onFocus={() => setActiveField("about")}
							/>
							{errors.about && touched.about && (
								<span className="mt-1 text-sm text-red-root">
									{errors.about}
								</span>
							)}
						</div>
						<div>
							<Label
								htmlFor="physical_description"
								className="flex gap-1 items-center mb-1"
							>
								Physical description
							</Label>
							<Textarea
								id="physical_description"
								name="physical_description"
								rows={3}
								placeholder="21, from LA, black hair, blue eyes, 5'2, skinny, a lot of tattoos"
								required
								value={values.physical_description}
								onChange={handleChange}
								onBlur={handleBlur}
								onFocus={() => setActiveField("physical_description")}
							/>
							{errors.physical_description && touched.physical_description && (
								<span className="mt-1 text-sm text-red-root">
									{errors.physical_description}
								</span>
							)}
						</div>
						<div>
							<Label htmlFor="tone" className="flex gap-1 items-center mb-1">
								Tone
							</Label>
							<Textarea
								id="tone"
								name="tone"
								rows={3}
								placeholder="Flirty, friendly, sarcastic"
								required
								value={values.tone}
								onChange={handleChange}
								onBlur={handleBlur}
								onFocus={() => setActiveField("tone")}
							/>
							{errors.tone && touched.tone && (
								<span className="mt-1 text-sm text-red-root">
									{errors.tone}
								</span>
							)}
						</div>
						<div className="space-y-2">
							<Label>Socials</Label>
							<Input
								id="instagram"
								name="instagram"
								type="url"
								placeholder="Instagram"
								className="mt-1"
								value={values.instagram}
								onChange={handleChange}
								onBlur={handleBlur}
								onFocus={() => setActiveField("socials")}
							/>
							{errors.instagram && touched.instagram && (
								<span className="mt-1 text-sm text-red-root">
									{errors.instagram}
								</span>
							)}
							<Input
								name="twitter"
								type="url"
								placeholder="Twitter"
								className="mt-1"
								value={values.twitter}
								onChange={handleChange}
								onBlur={handleBlur}
								onFocus={() => setActiveField("socials")}
							/>
							{errors.twitter && touched.twitter && (
								<span className="mt-1 text-sm text-red-root">
									{errors.twitter}
								</span>
							)}
							<Input
								id="reddit"
								name="reddit"
								type="url"
								placeholder="Reddit"
								className="mt-1"
								value={values.reddit}
								onChange={handleChange}
								onBlur={handleBlur}
								onFocus={() => setActiveField("socials")}
							/>
							{errors.reddit && touched.reddit && (
								<span className="mt-1 text-sm text-red-root">
									{errors.reddit}
								</span>
							)}
							<Input
								id="website"
								name="website"
								type="url"
								placeholder="OnlyFans"
								className="mt-1"
								value={values.website}
								onChange={handleChange}
								onBlur={handleBlur}
								onFocus={() => setActiveField("socials")}
							/>
							{errors.website && touched.website && (
								<span className="mt-1 text-sm text-red-root">
									{errors.website}
								</span>
							)}
						</div>
						{invite_content_creator && (
							<div className="space-y-2">
								<Label htmlFor="email">Content Creator Email (Optional)</Label>
								<Input
									id="email"
									name="email"
									type="email"
									placeholder="Enter content creator's email (optional)"
									className="mt-1"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
									onFocus={() => setActiveField("email")}
								/>
								{errors.email && touched.email && (
									<span className="mt-1 text-sm text-red-root">
										{errors.email}
									</span>
								)}
								<p className="text-sm text-gray-500">
									Optionally enter the email address of the content creator to
									invite them to the team. Leave blank if not applicable.
								</p>
							</div>
						)}
						<SubmitButton
							type="submit"
							className="w-full"
							is_loading={isSubmitting}
						>
							Create Persona
						</SubmitButton>
					</form>
				)}
			</Formik>
			<div className="col-span-1">
				<TooltipCard
					activeField={activeField}
					tooltipContent={tooltipContent}
				/>
			</div>
		</div>
	)
}

export default CreatePersona
