import { useState } from "react"
import { toast } from "sonner"
import trpc_client from "src/lib/trpc"

export const useStripePortal = () => {
	const [isLoading, setIsLoading] = useState(false)

	const openCustomerPortal = async () => {
		setIsLoading(true)
		try {
			const team = await trpc_client.user.getUsersTeam.query()

			if (!team?.id) {
				toast.error("Please create a team first")
				return
			}

			if (!team.stripe_customer_id) {
				toast.error("No subscription found")
				return
			}

			const { url } = await trpc_client.stripe.createPortalSession.mutate({
				team_id: team.id,
				return_url: window.location.href,
			})

			window.location.href = url
		} catch (error) {
			console.error("Error opening customer portal:", error)
			toast.error("Failed to open customer portal")
		} finally {
			setIsLoading(false)
		}
	}

	return {
		openCustomerPortal,
		isLoading,
	}
}
