import AnimatedGradientText from "src/components/effects/AnimatedGradientText"
import DotPattern from "src/components/effects/DottedPattern"
import { cn } from "src/lib/utils"

import { CTA } from "../ui/CTA"

export const Hero = () => {
	return (
		<section id="hero">
			<div className="overflow-hidden relative py-14 h-full">
				<div className="flex z-10 flex-col">
					<div className="grid grid-cols-1 mt-20">
						<div className="flex flex-col gap-6 items-center pb-8 mx-auto max-w-screen-2xl text-center">
							<AnimatedGradientText>
								<h1
									className={cn(
										`md:H0 H1 inline animate-gradient text-balance bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text tracking-tighter text-transparent`
									)}
									style={{
										transition:
											"opacity 0.6s ease 0.1s, transform 0.6s ease 0.1s",
									}}
								>
									We build relationships that drive your PPV sales
								</h1>
							</AnimatedGradientText>

							<p
								className="font-medium tracking-tight subheader text-balance text-stone-root"
								style={{
									transition:
										"opacity 0.6s ease 0.2s, transform 0.6s ease 0.2s",
								}}
							>
								Build stronger bond • Tailored fan experience • Always-on
								engagement
							</p>
							<h2 className="para text-balance text-stone-light">
								using AI Chatter Agents
							</h2>
							<div
								className="flex flex-col gap-4 lg:flex-row"
								style={{
									transition:
										"opacity 0.6s ease 0.3s, transform 0.6s ease 0.3s",
								}}
							>
								<CTA />
							</div>
						</div>
					</div>
					{/* <Grow /> */}
				</div>
			</div>
			<DotPattern
				width={20}
				height={20}
				cx={1}
				cy={1}
				cr={1}
				className={cn(
					"[mask-image:linear-gradient(to_bottom_right,white,transparent,transparent)]"
				)}
			/>
		</section>
	)
}
