import React from "react"

interface SectionHeaderProps {
	subheader: string
	title: string
	description: string
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
	subheader,
	title,
	description,
}) => {
	return (
		<div className="flex flex-col gap-2 p-8 pt-16 mx-auto max-w-screen-2xl text-center">
			<h4 className="subheader text-stone-light">{subheader}</h4>
			<h2 className="H2 text-balance text-stone-root sm:text-6xl">{title}</h2>
			<p className="mx-auto mt-2 max-w-2xl para text-balance text-stone-light">
				{description}
			</p>
		</div>
	)
}
