import DotPattern from "src/components/effects/DottedPattern"
import { CTA } from "src/components/ui/CTA"
import { cn } from "src/lib/utils"

import { FAQs } from "../components/blocks/FAQs"
import { FinalCTA } from "../components/blocks/FinalCTA"
import { Hero } from "../components/blocks/Hero"
import { How } from "../components/blocks/How"
import { Testimonials } from "../components/blocks/Testimonials"
import { Trust } from "../components/blocks/Trust"
import { Why } from "../components/blocks/Why"

export default function Home() {
	return (
		<main>
			<Hero />
			<Trust />
			<Why />
			<How />
			{/* <Pricing /> */}
			<section className="flex overflow-hidden relative flex-col justify-center items-center p-2 min-h-24 md:p-8">
				<div className="flex overflow-hidden flex-col flex-1 justify-center items-center w-full bg-white rounded-2xl  pt-12 pb-8">
					<p className="mb-8 w-full text-center subheader text-cream-mid">
						Chat with our team about your needs
					</p>
					<CTA />

					<DotPattern
						width={20}
						height={20}
						cx={1}
						cy={1}
						cr={1}
						className={cn(
							"[mask-image:linear-gradient(to_bottom_right,white,transparent,transparent)]"
						)}
					/>
				</div>
			</section>
			<Testimonials />
			<section className="flex overflow-hidden relative flex-col justify-center items-center p-2 min-h-24 md:p-8">
				<div className="flex overflow-hidden flex-col flex-1 justify-center items-center w-full bg-white rounded-2xl  pt-12 pb-8">
					<p className="mb-8 w-full text-center subheader text-cream-mid">
						Chat with our team about your needs
					</p>
					<CTA />

					<DotPattern
						width={20}
						height={20}
						cx={1}
						cy={1}
						cr={1}
						className={cn(
							"[mask-image:linear-gradient(to_bottom_right,white,transparent,transparent)]"
						)}
					/>
				</div>
			</section>
			<FAQs />
			<FinalCTA />
		</main>
	)
}
