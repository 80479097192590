import React, { useEffect } from "react"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"

// import trpc_client from "./lib/trpc"
import { useAuth } from "./providers/AuthProvider"
import AdminScreen from "./screens/admin/admin.screen" // Import the AdminScreen component
import Login from "./screens/auth/login.screen"
import Logout from "./screens/auth/logout.screen"
import Register from "./screens/auth/register.screen"
import VerifyEmail from "./screens/auth/verify.screen"
import { DashboardRoutes } from "./screens/dashboard"
import Home from "./screens/home"
import { OnboardingRoutes } from "./screens/onboarding"

interface ProtectedRouteProps {
	children: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
	const { user, is_loading } = useAuth()
	const navigate = useNavigate()

	useEffect(() => {
		if (!is_loading && !user) {
			navigate("/login")
		}
	}, [user, is_loading, navigate])

	if (is_loading) {
		return <div>Loading...</div> // or a loading spinner component
	}

	if (!user) {
		return <Navigate to="/login" replace />
	}

	return <>{children}</>
}

interface AdminProtectedRouteProps {
	children: React.ReactNode
}

const AdminProtectedRoute: React.FC<AdminProtectedRouteProps> = ({
	children,
}) => {
	const { user, is_loading } = useAuth()
	const navigate = useNavigate()

	useEffect(() => {
		if (!is_loading && (!user || user.email !== "nimeshnott@hotmail.co.uk")) {
			navigate("/")
		}
	}, [user, is_loading, navigate])

	if (is_loading) {
		return <div>Loading...</div>
	}

	if (!user || user.email !== "nimeshnott@hotmail.co.uk") {
		return <Navigate to="/" replace />
	}

	return <>{children}</>
}

function App() {
	return (
		<main className="py-24 mx-auto max-w-screen-2xl">
			<Routes>
				{/* Unprotected routes */}
				<Route path="/" element={<Home />} />
				<Route path="/login" element={<Login />} />
				<Route path="/logout" element={<Logout />} />
				<Route path="/verify" element={<VerifyEmail />} />
				<Route path="/register" element={<Register />} />

				{/* Protected routes */}
				<Route
					path="/*"
					element={
						<ProtectedRoute>
							<Routes>
								<Route path="/onboarding/*" element={<OnboardingRoutes />} />
								<Route
									path="/admin"
									element={
										<AdminProtectedRoute>
											<AdminScreen />
										</AdminProtectedRoute>
									}
								/>
								<Route path="/*" element={<DashboardRoutes />} />
							</Routes>
						</ProtectedRoute>
					}
				/>
			</Routes>
		</main>
	)
}

export default App
