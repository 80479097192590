import { useEffect, useState } from "react"
import { toast } from "sonner"
import trpc_client from "src/lib/trpc"

import {
	PublicSafePersona,
	UpdatePersonaDetails,
} from "../../../../../api/src/models/persona/persona.schema"

export const usePersona = (persona_id: string) => {
	const [persona, setPersona] = useState<PublicSafePersona | null>(null)
	const [isLoading, setIsLoading] = useState(true)

	const fetchPersona = async () => {
		setIsLoading(true)
		try {
			const fetchedPersona = await trpc_client.persona.getById.query({
				persona_id,
			})
			setPersona(fetchedPersona)
		} catch (error) {
			console.error("Error fetching persona:", error)
		} finally {
			setIsLoading(false)
		}
	}

	const updatePersona = async (data: Partial<PublicSafePersona>) => {
		try {
			await trpc_client.persona.update.mutate({
				persona_id,
				data: {
					name: data.name,
					avatar_image: data.avatar_image,
					tone: data.tone,
					physical_description: data.physical_description,
					about: data.about,
					twitter: data.twitter,
					instagram: data.instagram,
					reddit: data.reddit,
					website: data.website,
				},
			})
			await fetchPersona()
		} catch (error) {
			console.error("Error updating persona:", error)
		}
	}

	const updatePersonaDetails = async (data: Partial<UpdatePersonaDetails>) => {
		try {
			await trpc_client.persona.updateDetails.mutate({
				persona_id,
				data,
			})
			await fetchPersona()
			toast.success("Persona details updated successfully")
		} catch (error) {
			console.error("Error updating persona details:", error)
			toast.error("Failed to update persona details")
		}
	}

	useEffect(() => {
		if (persona_id) {
			fetchPersona()
		}
	}, [persona_id])

	return { persona, isLoading, updatePersona, updatePersonaDetails }
}
