import React, { useEffect, useState } from "react"
import { CheckIcon } from "lucide-react"
import { Link, useSearchParams } from "react-router-dom"
import { FAQs } from "src/components/blocks/FAQs"
import { Pricing } from "src/components/blocks/Pricing"
import AnimatedGradientText from "src/components/effects/AnimatedGradientText"
import ShineBorder from "src/components/effects/ShineBorder"
import Scenarios from "src/components/organisms/Scenarios/Scenarios.component"
import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/Avatar"
import { Button } from "src/components/ui/Button"
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/components/ui/Card"
import { Dialog, DialogContent } from "src/components/ui/Dialog"
import { Label } from "src/components/ui/Label"
import { Separator } from "src/components/ui/Separator"
import { CALL_BOOKING_URL } from "src/lib/constants"
import trpc_client from "src/lib/trpc"
import { cn } from "src/lib/utils"

import { ContentSetJSON } from "../../../../api/src/models/content/content_set.model"
import { PublicSafePersona } from "../../../../api/src/models/persona/persona.schema"
import { MagicCard } from "../../components/effects/MagicCard"

interface ProductInfo {
	price: number
	sellingPoints: string[]
}

interface PaymentBlockProps {
	productInfo: ProductInfo
}

const PaymentBlock: React.FC<PaymentBlockProps> = ({ productInfo }) => {
	const [showPricing, setShowPricing] = useState(false)

	return (
		<div className="flex flex-col gap-4 w-full">
			<ShineBorder
				borderRadius={18}
				borderWidth={4}
				color="#B3B3B3"
				className="p-0 w-full bg-stone-black"
			>
				<CardHeader>
					<CardTitle className="p-0 text-stone-white">
						<AnimatedGradientText className="flex flex-col items-start H3">
							Maximize Your PPV sales now with
							<br />
							<span
								className={cn(
									`inline animate-gradient bg-gradient-to-r from-[#ffa79e] via-[#E4CFFF] to-[#ffa79e] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent`
								)}
							>
								AI chatters
							</span>
						</AnimatedGradientText>
					</CardTitle>
					<CardDescription className="text-stone-light">
						<ul className="list-disc list-inside">
							<li>Build stronger relationships</li>
							<li>Tailored fan experience</li>
							<li>Always-on engagement</li>
						</ul>
						<Separator className="mt-8 bg-stone-mid" />
					</CardDescription>
				</CardHeader>
				<CardContent>
					<p className="mb-6 text-stone-pale subheader">Starter plan</p>
					<ul className="mb-6 list-disc list-inside text-stone-white">
						{productInfo.sellingPoints.map((point, index) => (
							<li
								key={index}
								className="flex items-center mb-2 text-stone-light"
							>
								<CheckIcon className="mr-2 w-5 h-5 text-green-mid" />
								{point}
							</li>
						))}
					</ul>
					<Separator className="mt-2 mb-4 bg-stone-mid" />

					<p className="mt-4 text-center detail text-stone-light">
						Starting from only <br />
					</p>
					<p className="mt-4 text-center H3 text-stone-white">
						${productInfo.price}{" "}
						<span className="text-stone-light detail">/ month</span>
					</p>
				</CardContent>
				<CardFooter className="flex flex-col items-center">
					<Button
						size="lg"
						className="mb-4 w-full bold"
						variant="secondary"
						onClick={() => setShowPricing(true)}
					>
						See pricing plans
					</Button>
				</CardFooter>
				<Card className="bg-transparent">
					<CardContent className="flex flex-row items-center w-full">
						<Separator className="flex-1 bg-stone-mid" />
						<p className="mx-4 text-stone-light">or</p>
						<Separator className="flex-1 bg-stone-mid" />
					</CardContent>

					<CardFooter>
						<Link to="/dashboard" className="w-full">
							<Button
								size="lg"
								className="w-full bold text-stone-white"
								variant="link"
							>
								Save and skip for now
							</Button>
						</Link>
					</CardFooter>
				</Card>
			</ShineBorder>

			<Card className="bg-stone-pale">
				<CardHeader>
					<CardTitle>Need help?</CardTitle>
				</CardHeader>
				<CardContent>
					<Link
						to={CALL_BOOKING_URL}
						target="_blank"
						className="flex flex-col items-center w-full"
					>
						<Button
							size="lg"
							variant="outline"
							className="flex flex-row gap-2 items-center w-full text-stone-root"
						>
							Book a 15 min call
						</Button>
						<p className="mt-2 subscript text-stone-light">With the founders</p>
					</Link>
				</CardContent>
			</Card>

			<Dialog open={showPricing} onOpenChange={setShowPricing}>
				<DialogContent className="max-w-[90vw] max-h-[90vh] overflow-y-auto rounded-3xl">
					<Pricing />
				</DialogContent>
			</Dialog>
		</div>
	)
}

export const ReviewScreen: React.FC = () => {
	const [searchParams] = useSearchParams()
	const persona_id = searchParams.get("persona_id")

	const [persona, setPersona] = useState<PublicSafePersona | null>(null)
	const [scenarios, setScenarios] = useState<ContentSetJSON[]>([])
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		const fetchPersonaAndScenarios = async () => {
			if (!persona_id) {
				setIsLoading(false)
				return
			}
			setIsLoading(true)
			try {
				const [fetchedPersona, fetchedScenarios] = await Promise.all([
					trpc_client.persona.getById.query({ persona_id }),
					trpc_client.content.set.getAll.query({ persona_id }),
				])
				setPersona(fetchedPersona)
				setScenarios(fetchedScenarios)
			} catch (error) {
				console.error("Error fetching persona and scenarios:", error)
			} finally {
				setIsLoading(false)
			}
		}
		fetchPersonaAndScenarios()
	}, [persona_id])

	if (isLoading) {
		return <div>Loading...</div>
	}

	if (!persona) {
		return <div>Persona not found</div>
	}

	const productInfo = {
		price: 1995.0,
		sellingPoints: [
			"Unlock AI-powered fan engagement",
			"Boost your content creation efficiency",
			"Increase your earnings potential",
			"24/7 customer support",
		],
	}
	const clientResults = [
		{
			number: "2-4x",
			text: "monthly revenue in just 3 months",
			color: "text-green-mid",
			bgColor: "bg-green-pale/60",
		},
		{
			number: "24/7",
			text: "availability support",
			color: "text-blue-root",
			bgColor: "bg-blue-pale/60",
		},
		{
			number: "4-5hr",
			text: "a week in time saved in admin work",
			color: "text-orange-root",
			bgColor: "bg-orange-pale/60",
		},
		{
			number: "300%",
			text: "increase in fan engagement",
			color: "text-purple-root",
			bgColor: "bg-purple-pale/60",
		},
	]

	return (
		<div className="flex flex-col gap-4 lg:flex-row">
			<Card className="w-full lg:w-2/3">
				<CardHeader>
					<CardTitle>Review Your Setup</CardTitle>
					<CardDescription>
						<b className="text-green-mid">All your settings are saved.</b>{" "}
						<br />
						Review your created account, persona and uploaded content and start
						your plan or continue.
					</CardDescription>
				</CardHeader>
				<CardContent className="flex flex-col gap-4">
					{/* Persona */}
					<Card className="w-full bg-stone-pale">
						<CardHeader>
							<CardTitle>Your Persona</CardTitle>
							<CardDescription>Your created persona details</CardDescription>
						</CardHeader>

						<CardHeader>
							<Avatar className="size-24">
								{persona.avatar_image ? (
									<AvatarImage
										src={persona.avatar_image}
										alt={persona.name ?? "Avatar"}
									/>
								) : (
									<AvatarFallback>
										{persona.name && persona.name.charAt(0).toUpperCase()}
									</AvatarFallback>
								)}
							</Avatar>
							<CardTitle>{persona.name}</CardTitle>
						</CardHeader>
						<CardContent>
							<CardDescription>
								<Label>About:</Label>
								<p className="para text-stone-root">{persona.about}</p>

								<Label>Physical Description:</Label>
								<p className="para text-stone-root">
									{persona.physical_description}
								</p>

								<Label>Tone:</Label>
								<p className="para text-stone-root">{persona.tone}</p>

								<Label>Social Media:</Label>
								<ul className="pl-5 list-disc">
									{persona.reddit && <li>Reddit: {persona.reddit}</li>}
									{persona.twitter && <li>Twitter: {persona.twitter}</li>}
									{persona.instagram && <li>Instagram: {persona.instagram}</li>}
								</ul>

								{persona.website && (
									<>
										<Label>Website:</Label>
										<p className="para text-stone-root">{persona.website}</p>
									</>
								)}
							</CardDescription>
						</CardContent>
					</Card>

					{/* Scenarios */}
					<Card className="w-full bg-stone-pale">
						<CardHeader>
							<CardTitle>Your Scenarios</CardTitle>
							<CardDescription>
								Created scenarios for your persona
							</CardDescription>
						</CardHeader>
						<CardContent>
							<Scenarios
								persona_id={persona.id}
								scenarios={scenarios}
								is_loading={false}
								refetchScenarios={() => {}}
								showAddButton={false}
							/>
						</CardContent>
					</Card>

					{/* Results */}
					<Card className="w-full">
						<CardHeader>
							<CardTitle>Results seen by our customers</CardTitle>
						</CardHeader>
						<CardContent>
							<div className="grid grid-cols-2 gap-4">
								{clientResults.map((item, index) => (
									<MagicCard
										key={index}
										className={`flex flex-col justify-center items-center text-4xl whitespace-nowrap  cursor-pointer ${item.bgColor}`}
										gradientColor="#FFF"
									>
										<CardHeader>
											<CardTitle className={`H1 text-center ${item.color}`}>
												{item.number}
											</CardTitle>
										</CardHeader>
										<CardContent>
											<CardDescription
												className={`flex flex-col items-center text-center bold para ${item.color}`}
											>
												{item.text}
											</CardDescription>
										</CardContent>
									</MagicCard>
								))}
							</div>
						</CardContent>
					</Card>

					<section className="w-full rounded-2xl bg-stone-pale">
						<FAQs />
					</section>
				</CardContent>
			</Card>

			<div className="w-full lg:w-1/3 lg:sticky lg:top-4 lg:self-start">
				<PaymentBlock productInfo={productInfo} />
			</div>
		</div>
	)
}
