import React from "react"
import { Loader } from "lucide-react"
import { Link } from "react-router-dom"
import { Pricing } from "src/components/blocks/Pricing"
import { Button } from "src/components/ui/Button"
import { useStripePortal } from "src/hooks/useStripePortal"
import { useSubscription } from "src/hooks/useSubscription"
import { useAuth } from "src/providers/AuthProvider"

import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../../../components/ui/Card"
import { Input } from "../../../components/ui/Input"
import { Label } from "../../../components/ui/Label"

export const SettingsScreen = () => {
	const { user } = useAuth()
	const { openCustomerPortal, isLoading: isPortalLoading } = useStripePortal()
	const { subscription, isLoading: isSubscriptionLoading } = useSubscription()

	const subscriptionStatusMap = {
		active: { text: "Active", color: "text-green-500" },
		canceled: { text: "Canceled", color: "text-muted-foreground" },
		inactive: {
			text: "No active subscription",
			color: "text-muted-foreground",
		},
	}

	const { text, color } =
		subscriptionStatusMap[user?.is_subscribed ? "active" : "inactive"]

	return (
		<div className="flex flex-col gap-4 p-4 w-full min-h-screen bg-muted/40">
			<Card>
				<CardHeader>
					<CardTitle>Details</CardTitle>
				</CardHeader>
				<CardContent>
					<form className="max-w-md">
						<div className="mb-4">
							<Label htmlFor="name">Name</Label>
							<div className="flex flex-row gap-2">
								<Input
									type="text"
									id="given_name"
									value={user?.given_name || ""}
									disabled
									placeholder="First Name"
								/>
								<Input
									type="text"
									id="family_name"
									value={user?.family_name || ""}
									disabled
									placeholder="Last Name"
								/>
							</div>
						</div>
						<div className="mb-4">
							<Label htmlFor="email">Email</Label>
							<Input
								type="email"
								id="email"
								value={user?.email || ""}
								disabled
								placeholder="email@example.com"
							/>
						</div>
					</form>
				</CardContent>
				<CardFooter>
					<Link to="mailto:hello@ofa.app?subject=Update Account Details">
						<Button variant="outline">Contact Support</Button>
					</Link>
				</CardFooter>
			</Card>

			{isSubscriptionLoading ? (
				<Card>
					<CardContent className="flex justify-center py-8">
						<Loader className="animate-spin" />
					</CardContent>
				</Card>
			) : user?.is_subscribed ? (
				<Card>
					<CardHeader>
						<CardTitle>Subscription</CardTitle>
					</CardHeader>
					<CardContent>
						<div className="flex flex-col gap-4">
							<div>
								<Label>Status</Label>
								<p className={`text-sm ${color}`}>{text}</p>
							</div>
							{subscription?.subscription_end_date && (
								<div>
									<Label>Next billing date</Label>
									<p className="text-sm text-muted-foreground">
										{new Date(
											subscription.subscription_end_date
										).toLocaleDateString()}
									</p>
								</div>
							)}
						</div>
					</CardContent>
					<CardFooter>
						<Button
							onClick={openCustomerPortal}
							disabled={isPortalLoading}
							variant="outline"
						>
							{isPortalLoading && (
								<Loader className="mr-2 w-4 h-4 animate-spin" />
							)}
							Manage Subscription
						</Button>
					</CardFooter>
				</Card>
			) : (
				<>
					<Card>
						<CardHeader>
							<CardTitle>Subscription</CardTitle>
						</CardHeader>
						<CardContent>
							<p className="text-sm text-muted-foreground">
								You don&apos;t have an active subscription. Choose a plan below
								to get started.
							</p>
						</CardContent>
					</Card>
					<Pricing />
				</>
			)}
		</div>
	)
}
