export interface Question {
	question: string
	display_text?: string
	answer: string
	type?: "input" | "textarea" | "select"
	options?: string[]
	condition?: { field: string; value: string }
}

export interface QuestionGroup {
	title: string
	questions: (Question | Question[])[]
}

export const initialQuestions: QuestionGroup[] = [
	{
		title: "Basic Information",
		questions: [
			{
				question: "gender",
				display_text: "Gender",
				answer: "",
				type: "select",
				options: ["Male", "Female"],
			},
			[
				{ question: "age", display_text: "Age", answer: "" },
				{ question: "birthday", display_text: "Birthday", answer: "" },
			],
			[
				{ question: "height", display_text: "Height", answer: "" },
				{ question: "shoe_size", display_text: "Shoe Size", answer: "" },
			],
			[
				{ question: "home_town", display_text: "Home Town", answer: "" },
				{
					question: "current_location",
					display_text: "Current Location",
					answer: "",
				},
			],
			[
				{ question: "education", display_text: "Education", answer: "" },
				{
					question: "profession_job",
					display_text: "Profession/Job",
					answer: "",
				},
			],
			[
				{ question: "language", display_text: "Language", answer: "" },
				{
					question: "relationship_status",
					display_text: "Relationship Status",
					answer: "",
				},
			],
			{
				question: "cock_size",
				display_text: "Cock Size",
				answer: "",
				condition: { field: "gender", value: "Male" },
			},
			{
				question: "boob_size",
				display_text: "Boob Size",
				answer: "",
				condition: { field: "gender", value: "Female" },
			},
			{
				question: "waist_size",
				display_text: "Waist Size",
				answer: "",
				condition: { field: "gender", value: "Female" },
			},
			{
				question: "model_niche",
				display_text: "Model Niche",
				answer: "",
			},
			{
				question: "tattoos",
				display_text: "Tattoos",
				answer: "",
				type: "textarea",
			},
		],
	},
	{
		title: "Lifestyle",
		questions: [
			{
				question: "hobbies",
				display_text: "Hobbies",
				answer: "",
				type: "textarea",
			},
			{
				question: "who_do_you_live_with",
				display_text: "Who Do You Live With",
				answer: "",
				type: "textarea",
			},
			{
				question: "favourite_restaurants",
				display_text: "Favourite Restaurants",
				answer: "",
				type: "textarea",
			},
			{
				question: "night_out_habits",
				display_text: "Night Out Habits",
				answer: "",
				type: "textarea",
			},
			{
				question: "upcoming_holiday_plans",
				display_text: "Upcoming Holiday Plans",
				answer: "",
				type: "textarea",
			},
			{
				question: "perfect_date",
				display_text: "Perfect Date",
				answer: "",
				type: "textarea",
			},
			{
				question: "best_place_visited",
				display_text: "Best Place Visited",
				answer: "",
				type: "textarea",
			},
		],
	},
	{
		title: "Favorites",
		questions: [
			{
				question: "music",
				display_text: "Music",
				answer: "",
				type: "textarea",
			},
			{
				question: "tv_film",
				display_text: "TV/Film",
				answer: "",
				type: "textarea",
			},
			{
				question: "food",
				display_text: "Food",
				answer: "",
				type: "textarea",
			},
			{
				question: "color",
				display_text: "Color",
				answer: "",
			},
			{
				question: "sports",
				display_text: "Sports",
				answer: "",
				type: "textarea",
			},
		],
	},
	{
		title: "Future",
		questions: [
			{
				question: "future_plans",
				display_text: "Future Plans",
				answer: "",
				type: "textarea",
			},
		],
	},
]
